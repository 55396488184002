<template>
    <section class="listing-application">
        <div class="top-bar bg-white">
            <el-date-picker class="data" v-model="dateArr" type="datetimerange" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期" size="small"/>
            <!--    搜索框-->
            <el-input v-model="formSearch.keyword" placeholder="请输入内容" style="margin: 0 9px; width: 250px"
                      size="small"/>
            <el-button type="primary" @click="handleChange" size="small">查询</el-button>
            <el-button size="small" @click="handleResetting">重置</el-button>

        </div>
        <r-e-table class="bg-white" ref="tableRef" :dataRequest="getApplyPageList" :height="820" :columns="tableColumn"
                   :query="formSearch">
            <el-table-column slot="toolbar" label="操作" width="200">
                <template slot-scope="{ row:{uuid,status} }">
                    <div class="table-tools">
                        <span v-if="status===0" class="table-btn" @click="handleCancel(uuid)">处理</span>
                    </div>
                </template>
            </el-table-column>
            <template slot="empty">
                <el-empty/>
            </template>
        </r-e-table>
    </section>
</template>

<script>
    import { tableColumn } from "@/views/rental-housing-management/listing-application/data";
    import { getApplyPageList, booking } from '@/api/listing-application';
    import { timeFormat } from "@custom";
    import { MessageSuccess } from "@custom/message";
    export default {
        name: "listing-application",
        data() {
            return {
                tableColumn,
                formSearch: {
                    keyword: null,
                    startDate: null,
                    endDate: null,
                },
                dateArr: [],
                loadingOptions: {
                    lock: true,
                    text: "Loading...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)"
                },
            };
        },
        components: { },
        created() {},
        mounted() {},
        methods: {
            getApplyPageList(params) {
                return getApplyPageList(params);
            },

            handleChange() {
                this.$refs["tableRef"].getTableData();
            },

            handleResetting(){
                this.formSearch.keyword = null;
                this.formSearch.startDate = null;
                this.formSearch.endDate = null;
                this.handleChange();
            },

            handleCancel(uuid){
                let that = this;
                this.$confirm('检测到您要预约该房源，预约已处理！', '确认', {
                    distinguishCancelAndClose: true,
                    confirmButtonText: '预约',
                    cancelButtonText: '取消'
                }).then(() => {
                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading({...loadingOptions});
                    booking({uuid}).then(() =>{
                        MessageSuccess("处理成功");
                    }).finally(() => loading.close());
                }).catch(() => {});
            }
        },
        watch:{
            dateArr(value){
                if(value && value.length === 2){
                    const [startDate,endDate] = value;
                    this.formSearch.startDate = timeFormat((new Date(startDate)), 'yyyy-MM-dd HH:mm:ss');
                    this.formSearch.endDate = timeFormat((new Date(endDate)), 'yyyy-MM-dd HH:mm:ss');
                } else {
                    this.formSearch.startDate = null;
                    this.formSearch.endDate = null;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .listing-application {
        padding: 0 VW(15px);

        .top-bar {
            display: flex;
            margin: VH(10px) 0 VH(15px);
            padding: VH(15px) VW(15px);
            //justify-content: center;
        }

        /deep/ .r-e-table {
            .el-table .cell {
                white-space: nowrap;
                text-align: center;
            }
        }
    }
</style>