import request from "@http";

// 房源申请
export function getApplyPageList(params) {
    return request({
        method: "get",
        url: `/api/gzf/ready/apply/page`,
        params,
    });
}

// 房源预约
export function booking(params) {
    return request({
        method: "get",
        url: `/api/gzf/ready/apply/handle`,
        params,
    });
}