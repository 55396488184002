import { timeFormat, numberFormat } from "@custom";

export const tableColumn = [
    {
        prop: "contactsName",
        label: "联系人",
        showOverflowTooltip: true
    },
    {
        prop: "contactsPhone",
        label: "联系方式",
        // width: 100
    },
    {
        prop: "readyApartment.district",
        label: "意向地址",
        /*width: 200,
        render: (h, {row}) => {
            const timeStamp = !row.timestamp ? "" : timeFormat(new Date(row.timestamp), 'yyyy-MM-dd HH:mm:ss');
            return h("span", timeStamp);
        }*/
    },
    {
        prop: "readyApartment.area",
        label: "意向面积m²",
        render: (h, {row: {readyApartment:{area}}}) => {
            const timeStamp = area ? area + "m²" : '';
            return h("span", timeStamp);
        }
    },
    {
        prop: "readyCommon",
        label: "备注"
    },
    {
        prop: "contactTime",
        label: "申请时间",
        render: (h, {row}) => {
            const contactTime = !row.contactTime ? "" : timeFormat(new Date(row.contactTime), 'yyyy-MM-dd HH:mm:ss');
            return h("span", contactTime);
        }
    },
    {
        prop: "status",
        label: "预约状态",
        render: (h, {row:{status}}) => {
            status = status === 0 ? "待查看" : "已完成";
            return h("span", status);
        }
    }
];

